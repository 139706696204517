export const FETCH_DEVICES_REQUEST = 'devices/FETCH_DEVICES_REQUEST'
export const FETCH_DEVICES_SUCCESS = 'devices/FETCH_DEVICES_SUCCESS'
export const FETCH_DEVICES_FAILURE = 'devices/FETCH_DEVICES_FAILURE'

export const FETCH_DEVICE_INFO_REQUEST = 'devices/FETCH_DEVICE_INFO_REQUEST'
export const FETCH_DEVICE_INFO_SUCCESS = 'devices/FETCH_DEVICE_INFO_SUCCESS'
export const FETCH_DEVICE_INFO_FAILURE = 'devices/FETCH_DEVICE_INFO_FAILURE'

export const CLEAR_CURRENT_DEVICE = 'devices/CLEAR_CURRENT_DEVICE'

export const SET_DEVICE_SEARCH_QUERY = 'devices/SET_DEVICE_SEARCH_QUERY'
