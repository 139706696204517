import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Tooltip,
  ListItemSecondaryAction,
} from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'

export default function DevicePlaceholder({ ...props }) {
  return (
    <ListItem style={{ paddingLeft: 0 }}>
      <ListItemIcon>
        <Skeleton width={44} height={32} />
      </ListItemIcon>
      <ListItemIcon>
        <Skeleton width={44} height={32} />
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton />}
        secondary={<Skeleton />}
      ></ListItemText>
    </ListItem>
  )
}

// ;<Skeleton width="100%" style={{ width: '100%', height: 74 }} />
