import styled from 'styled-components'

const FiltersWrapper = styled.div`
  && {
    display: flex;
    background: ${(p) => p.theme.palette.background.primary};
    overflow: visible;
    flex-wrap: wrap;
    & > * {
      margin-right: 1rem;
      margin-bottom: 0.625rem;
    }
    &::-webkit-scrollbar {
      height: 1px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(192, 192, 192);
    }
  }
`

export default FiltersWrapper
